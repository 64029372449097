<template>
	<!-- 导航 -->
	<div class="home-nav clearfix">
		<leftMenu :categoryList="categoryList"></leftMenu>
		<!-- <navigation class=""></navigation> -->
		<!-- 轮播 -->
		<div class="carousel">
			<el-carousel height="360px" :interval="6000">
				<el-carousel-item v-for="(item,index) in carouselList" :key="index">
					<div class="carousel-img" :style="{background: item.color}"><img :src="item.img" /></div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 最新资讯 -->
		<div class="personal">
			<div class="personal-con">
				<div class="header" v-if="userId">
					<img src="@/assets/img/man.png" alt="">
					<div class="info">
						<p class="line1" :title="userInfo.nick_name"><router-link to="/myhome">Hi，{{ userInfo.nick_name }}</router-link></p>
						<p class="pointer" @click="logout">退出</p>
					</div>
				</div>
				<ul class="classify" v-if="userId">
					<router-link :to="{ path: '/myhome/myorder', query: { dataType: 'payment' }}">
						<li><em>{{ todoCounts.payment }}</em><span>待支付</span></li>
					</router-link>
					<router-link :to="{ path: '/myhome/myorder', query: { dataType: 'delivery' }}">
						<li><em>{{ todoCounts.received }}</em><span>待发货</span></li>
					</router-link>
					<router-link :to="{ path: '/myhome/myorder', query: { dataType: 'received' }}">
						<li><em>{{ todoCounts.delivery }}</em><span>待收货</span></li>
					</router-link>
					<router-link :to="{ path: '/myhome/myorder', query: { dataType: 'comment' }}">
						<li><em>{{ todoCounts.comment }}</em><span>待评价</span></li>
					</router-link>
				</ul>
				<router-link to='/myhome/quick'><el-button type="danger" class="order-btn"><img src="@/assets/img/btn-icon.png"
							alt="">快速下单</el-button></router-link>
			</div>
			<!-- <div class="home-title"><span><i class="iconfont">&#xe661;</i>优惠活动</span><router-link to='/myhome/coupon'>更多
					></router-link></div> -->
			<div class="activity">
				<router-link to='/myhome/quick'><img src="@/assets/img/quick-order.jpg" class="ad" /></router-link>
				<!-- <router-link to=''><img src="@/assets/img/ad02.jpg" class="ad" /></router-link> -->
			</div>
			<div class="home-title"><span><i class="iconfont">&#xe603;</i>公告资讯</span><router-link to='/newslist'>更多
					></router-link></div>
			<div class="news-con">
				<ul class="news-list">
					<li class="line1" v-for="(item,index) in newsList" :key="index" v-if="index<2">
						<router-link :to="'/newsdetails?id='+item.article_id">
							<span v-if="index==0">最新</span>
							<span v-if="index!==0">{{item.category.name}}</span>
							{{item.title}}
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import * as UserApi from '@/api/user'
  import * as OrderApi from '@/api/order'
	import leftMenu from "@/components/menu.vue"
	import navigation from "@/components/navigation.vue"
	export default {
		components: {
			leftMenu,
			navigation
		},
		props: {
			items: Array,
			categoryList: Array,
			userId:String
		},
		data() {
			return {
				// 当前用户信息
				userInfo: {},
				pageIndex: 0,
				carouselList: [{
					id: 1,
					color: '#104a94',
					img: require("@/assets/img/banner01.jpg")
				}, {
					id: 1,
					color: '#2b6082',
					img: require("@/assets/img/banner02.jpg")
				}, {
					id: 1,
					color: '#3974ae',
					img: require("@/assets/img/banner03.jpg")
				}],
				newsList: [],
                todoCounts: { payment: 0, deliver: 0, received: 0, comment: 0 },
			}
		},
		watch: {
			items() {
				let app = this
				app.items.forEach(item => {
					if (item.name == '文章组') {
						app.newsList = item.data
					}
				})
			},
		},
		created() {
			if(this.userId){
				this.getUserInfo()
				this.getTodoCount()
			}
		},
		methods: {
			logout() {
				this.$confirm('确定要退出吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					localStorage.clear()
					this.$router.go(0)
				}).catch(() => {
				});
			},
			getUserInfo() {
				let app = this
				UserApi.info()
					.then(result => {
						app.userInfo = result.data.userInfo
					})
			},
            getTodoCount() {
                let app = this
                OrderApi.todoCounts()
                    .then(result => {
                        app.todoCounts = result.data.counts
                    })
            }
		}
	}
</script>

<style lang="scss">
	.is-animating {
		transition: transform 1.5s ease-in-out !important;
	}
</style>
