import request from '@/utils/request'

// api地址
const api = {
  userInfo: 'user/info',
  assets: 'user/assets',
  updatename: 'user/updatename'
}

// 当前登录的用户信息
export const info = (param, option) => {
  const options = {
    isPrompt: true, //（默认 true 说明：本接口抛出的错误是否提示）
    load: true, //（默认 true 说明：本接口是否提示加载动画）
    ...option
  }
  return request({
      url: api.userInfo,
      method: 'get',
  	params: param,
    })
}

// 账户资产
export const assets = (param, option) => {
  return request({
      url: api.assets,
      method: 'get',
  	params: param,
    })
}

export const updatename = (nick_name) => {
  return request({
      url: api.updatename,
      method: 'post',
  	data: nick_name,
    })
}
