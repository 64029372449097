<template>
	<div class="main-header">
		<headerTop></headerTop>
		<div class="header-con clearfix">
			<div class="wrapper">
				<div class="logo">
					<router-link to='/'>
						<img src="../assets/img/logo.png" />
					</router-link>
				</div>
				<router-link to='/cart'>
					<div class="cart">
						<span>我的购物车</span>
						<div class="icon">
							<i class="iconfont">&#xe604;</i>
							<em class="dot">{{cartNum}}</em>
						</div>
					</div>
				</router-link>
				<div class="search">
					<el-input placeholder="请输入备品零件号" class="input-with-select" v-model="search"  @keyup.enter.native="goSearch">
						<el-button slot="append" @click="goSearch">搜索备品</el-button>
					</el-input>
					<!-- <div class="keyword">
						<span class="hot" v-for="(item,index) in historyWord" :key="index" @click="goHotSearch(item)"
						 v-if="index<5">
							{{item}}
						</span>
					</div> -->
				</div>

				<!-- <router-link to='/myhome/quick'>
					<el-button type="primary" icon="el-icon-tickets" class="place-order">快速下单</el-button>
				</router-link> -->
			</div>
		</div>
	</div>
</template>

<script>
	import * as CartApi from '@/api/cart'
	import headerTop from "./header-top.vue"
	export default {
		components: {
			headerTop
		},
		props: {
			cartAdd: Number
		},
		data() {
			return {
				cartNum: 0,
				search: '',
				historyWord: [],
			};
		},
		watch: {
			cartAdd() {
				this.getCartNum()
			}
		},
		created() {
			this.getCartNum()
			this.getHistoryWord()
		},
		methods: {
			getHistoryWord() {
				let list = localStorage.getItem('search')
				if (list) {
					this.historyWord = JSON.parse(list)
				}
			},
			getCartNum() {
				let app = this
				CartApi.total().then(response => {
					// console.log(response)
					app.cartNum = response.data.cartTotal
				})
			},
			goSearch() {
				if (this.search) {
					// this.$router.push('/productlist?search=' + this.search)
					this.$router.push({
						path:'/productlist',
						query:{
							search:this.search
						}
					})
				}
			},
			goHotSearch(word) {
				if (word) {
					this.$router.push('/productlist?search=' + word)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>