<template>
	<div class="product">
		<el-tabs v-model="activeName" class="main-tabs">
			<el-tab-pane :label="item.title" :name="item.title" v-for="(item,index) in productList" :key="index"
				class="product-item">
				<span slot="label"><i class="iconfont" v-html="item.icon"></i> {{item.title}}</span>
				<div class="product-list ">
					<el-empty description="暂无产品" :image-size="50" v-if="item.product.length===0"></el-empty>
					<ul class="clearfix">
						<li v-for="(v,i) in item.product" :key="i">
							<router-link :to="'/details?id='+v.goods_id">
								<img :src="v.goods_image" />
								<p class="line1"><span>备件号：</span>{{v.goods_no}}</p>
								<p class="line2"><span>名称：</span>{{v.goods_name}}</p>
								<!-- <p><span>适用车型：</span>{{v.type}}</p> -->
								<p class="p-bottom">
									<span class="price">￥{{v.goods_price_min}}</span>
									<!-- <em class="cart-icon" :class="[{'active':v.cartSelect},{'shake':v.cartSelect}]"
											@click.prevent="addCart(v,i)">
											<i class="iconfont cart">&#xe604;</i>
											<i class="iconfont select" v-if="v.cartSelect">&#xe6b2;</i>
										</em> -->
								</p>
							</router-link>
						</li>
					</ul>
					<router-link :to="{ path: '/productlist', query: { categoryId: item.category_id }}" class="more">查看更多
						></router-link>
					<!-- <ul class="class">
							<li v-for="(value,indexs) in item.class" :key="indexs">
								<router-link :to="{ path: '/productlist', query: { categoryId: value.category_id }}"
									class="more">{{value.name}}</router-link>
							</li>
						</ul> -->
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import * as GoodsApi from '@/api/goods'
	export default {
		props: {
			categoryList: Array
		},
		data() {
			return {
				activeName: '',
				productList: []
			}
		},
		watch: {
			categoryList() {
				this.getCategoryList()
			}
		},
		created() {},
		methods: {
			getCategoryList() {
				let app = this
				app.productList = []
				app.categoryList.forEach((item, index) => {
					app.productList.push({
						category_id: item.category_id,
						title: item.name,
						product: []
					})
					switch (index) {
						case 0:
							app.productList[index].icon = "&#xe7dc;"
							break;
						case 1:
							app.productList[index].icon = "&#xe626;"
							break;
						case 2:
							app.productList[index].icon = "&#xe626;"
							break;
						case 3:
							app.productList[index].icon = "&#xe649;"
							break;
						case 4:
							app.productList[index].icon = "&#xe719;"
							break;
						case 5:
							app.productList[index].icon = "&#xe667;"
							break;
						case 6:
							app.productList[index].icon = "&#xe62a;"
							break;
						default:
							app.productList[index].icon = "&#xe822;"
					}
					if (item.children && item.children.length > 0) {
						app.productList[index].class = item.children
					} else {
						app.productList[index].class = []
					}
				})
				app.activeName = app.productList[0].title
				app.productList.forEach((item, index) => {
					app.initProduct(index, item)
				})
			},
			initProduct(index, item) {
				let app = this
				GoodsApi.list({
					categoryId: item.category_id
				}).then(response => {
					// console.log(response)
					app.productList[index].product = response.data.list.data.slice(0, 10)
				}).catch(err => {
					console.log(err)
				})
			},
			addCart(v) {
				if (!localStorage.getItem('userId')) {
					this.$message({
						message: '温馨提示：请先登录！',
						type: 'warning'
					});
				}
				v.cartSelect = !v.cartSelect
			}
		}
	}
</script>

<style>
</style>