import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import router from '../router'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 500000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (localStorage.getItem('token')) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['platform'] = 'PC'
      config.headers['Access-Token'] = localStorage.getItem('token')
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (!res.status || res.status != 200) {
      Message({
        message: res.message,
        type: 'error',
        duration: 3 * 1000,
				customClass: 'messageIndex'
      })


      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.status === 401 || res.status === 403) {
				localStorage.clear()
		// Message({
		//   message: '你的登录状态已过期，请重新登录',
		//   type: 'error',
		//   duration: 3 * 1000
		// })
		// router.push("/login")
        // to re-login
        // MessageBox.confirm('你的登录状态已过期，请重新登录', '提示', {
        //   confirmButtonText: '重新登录',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   router.push("/login");
        // })
      }
      return Promise.reject(new Error(res.message || '出错'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 3 * 1000,
			customClass: 'messageIndex'
    })
    return Promise.reject(error)
  }
)

export default service
