import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// scss重置
// import './assets/css/reset.scss'

// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
ElementUI.Dialog.props.closeOnClickModal.default = false; // 全局关闭点遮罩关闭弹框

//vant
import "vant/lib/index.css";
import { Button,Dialog,Popup,Toast,Stepper,Pagination,Cell, CellGroup ,Loading ,Tab, Tabs,Tag,Form,Field, RadioGroup,Radio,Switch,Uploader } from "vant"; // 按需引入,优化加载速度

Vue.use(Button);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Stepper);
Vue.use(Pagination);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Loading);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(Form);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Switch);
Vue.use(Uploader);

//axios
import axios from 'axios'
Vue.prototype.$axios = axios

// 让页面回到顶部
router.beforeEach((to, from, next) => {
	// chrome
	document.body.scrollTop = 0;
	// firefox
	document.documentElement.scrollTop = 0;
	// safari
	// window.pageYOffset = 0;
	next()
})

Vue.config.productionTip = false
// 路由守卫
router.beforeEach((to, from, next) => {
	let token = localStorage.getItem('userId')
	if (to.meta.requireLogin) {
		if (token) {
			next()
		} else {
			next({
				path: '/login'
			})
			// ElementUI.Message.error('请先登录!')
		}
	} else {
		next()
	}
})
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
