import request from '@/utils/request'

// api地址
const api = {
  todoCounts: 'order/todoCounts',
  list: 'order/list',
  detail: 'order/detail',
  express: 'order/express',
  cancel: 'order/cancel',
  receipt: 'order/receipt',
  pay: 'order/pay',
  express_list: 'order/express_list',
  invoice: 'order/invoice',
  invoiceview: 'order/invoiceview',
  updateremark: 'order/updateremark',
  tdsreceive: 'order/tdsreceive',
    getMpPathQrcode: 'order/getMpPathQrcode',
}

// 当前用户待处理的订单数量
export function todoCounts(param) {
  return request({
      url: api.todoCounts,
      method: 'get',
  	params: param,
    })
}

// 我的订单列表
export function list(param, option) {
  return request({
      url: api.list,
      method: 'get',
  	params: param,
    })
}

// 订单详情
export function detail(orderId, param) {
  return request({
    url: api.detail,
    method: 'get',
	params: { orderId, ...param }
  })
}

// 获取物流信息
export function express(orderId, param) {
  return request({
      url: api.express,
      method: 'get',
  	params: { orderId, ...param }
    })
}

// 取消订单
export function cancel(orderId, data) {
  return request({
      url: api.cancel,
      method: 'post',
	  data: { orderId, ...data }
    })
}

// 确认收货
export function receipt(orderId, data) {
  return request({
      url: api.receipt,
      method: 'post',
	  data: { orderId, ...data }
    })
}

// 立即支付
export function pay(orderId, payType, param) {
  return request({
      url: api.pay,
      method: 'get',
  	params: { orderId, payType, ...param }
    })
}

export function express_list(param, option) {
  return request({
      url: api.express_list,
      method: 'get',
  	params: param,
    })
}

export function invoice(orderId, buyerName, buyerTaxNum, buyerTel, buyerAddress, buyerAccount, pushMode,
		buyerPhone, email, invoiceLine) {
  return request({
      url: api.invoice,
      method: 'post',
	  data: { orderId, buyerName, buyerTaxNum, buyerTel, buyerAddress, buyerAccount, pushMode,
		buyerPhone, email, invoiceLine }
    })
}

export function invoiceview(serialNos) {
  return request({
      url: api.invoiceview,
      method: 'post',
	  data: { serialNos }
    })
}

export function updateremark(orderGoodsId, remark) {
  return request({
      url: api.updateremark,
      method: 'post',
	  data: { orderGoodsId, remark }
    })
}

export function tdsreceive(orderId, tds) {
  return request({
      url: api.tdsreceive,
      method: 'post',
	  data: { orderId, tds }
    })
}

export function getMpPathQrcode(orderId) {
  return request({
      url: api.getMpPathQrcode,
      method: 'get',
  	params: { orderId }
    })
}
