import request from '@/utils/request'

// api地址
const api = {
    list: 'cart/list',
    total: 'cart/total',
    add: 'cart/add',
    update: 'cart/update',
    clear: 'cart/clear',
    importUrl: 'cart/import',
    errlist: 'cart/errlist',
    delerr: 'cart/delerr'
}

// 购物车列表
export const list = () => {
  return request({
    url: api.list,
    method: 'get',
  })
}

// 购物车商品总数量
export const total = () => {
  return request({
      url: api.total,
      method: 'get',
  })
}

// 加入购物车
export const add = (goodsId, goodsSkuId, goodsNum, categoryId, warehouse_name) => {
	let data = { goodsId, goodsSkuId, goodsNum, categoryId, warehouse_name }
	return request({
		url: api.add,
		method: 'post',
		data
	})
}

// 更新购物车商品数量
export const update = (goodsId, goodsSkuId, goodsNum, categoryId, warehouse_name) => {
	let data = { goodsId, goodsSkuId, goodsNum, categoryId, warehouse_name }
  return request({
      url: api.update,
      method: 'post',
  	  data
    })
}

// 删除购物车中指定记录
export const clear = (cartIds = []) => {
	let data = { cartIds }
  return request({
      url: api.clear,
      method: 'post',
	  data
    })
}

export const goodsImport = (fileId, categoryId) => {
	let data = { fileId, categoryId }
	return request({
		url: api.importUrl,
		method: 'post',
		data
	})
}

export const errlist = () => {
  return request({
    url: api.errlist,
    method: 'get',
  })
}

export const delerr = (id) => {
	let data = { id }
	return request({
		url: api.delerr,
		method: 'post',
		data
	})
}
