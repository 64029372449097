<template>
	<div class="main-footer clearfix">
		<advertisement v-if="route!=='/'"></advertisement>
		<div class="service clearfix">
			<ul class="wrapper">
				<li>
					<img src="../assets/img/service-icon01.png" />
					<span>正品保证</span>
				</li>
				<li>
					<img src="../assets/img/service-icon02.png" />
					<span>优质服务</span>
				</li>
				<li>
					<img src="../assets/img/service-icon03.png" />
					<span>安全支付</span>
				</li>
				<li>
					<img src="../assets/img/service-icon04.png" />
					<span>极速物流</span>
				</li>
				<li>
					<img src="../assets/img/service-icon05.png" />
					<span>售后无忧</span>
				</li>
			</ul>
		</div>
		<div class="center clearfix">
			<ul class="wrapper">
				<li v-for="(item,index) in serviceList" :key="index">
					<div class="title">
						<i class="iconfont" v-html="item.icon"></i>
						{{item.title}}
					</div>
					<div class="subhead">
						<router-link to=''>
							<span v-for="(value,i) in item.subhead" :key="i">{{value.name}}</span>
						</router-link>
					</div>
				</li>
			</ul>
		</div>
		<div class="wrapper bottom">
			<div class="qr-code">
				<div class="qr-code-item">
					<img src="../assets/img/gzh.jpg"/>
					<span>公众号</span>
				</div>
				<div class="qr-code-item">
					<img src="../assets/img/weidian.png"/>
					<span>微店</span>
				</div>
				<div class="qr-code-item">
					<img src="../assets/img/douyin.png"/>
					<span>抖音</span>
				</div>
			</div>
			<p><span>Copyright © {{new Date().getFullYear()}} 版权所有 青岛众创版权所有</span><span>鲁ICP备2023028523号-1</span></p>
			<p>	<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37021202001491"><img src="@/assets/img/beian.png"/>鲁公网安备 37021202001491号</a ></p>
		</div>
	</div>
</template>

<script>
	import advertisement from '@/components/advertisement.vue'
	export default{
		components: {
			advertisement
		},
		data() {
			return{
				route:'',
				serviceList:[{
					title:"购物指南",
					icon:"&#xe809;",
					subhead:[{name:"新手入门"},{name:"购物流程"},{name:"发票制度"}]
				},{
					title:"售后服务",
					icon:"&#xe629;",
					subhead:[{name:"售后服务范围"},{name:"退换货流程"},{name:"服务保障与承诺"}]
				},{
					title:"支付方式",
					icon:"&#xe641;",
					subhead:[{name:"网上支付"},{name:"货到付款"},{name:"银行汇款"},{name:"银行转账"}]
				},{
					title:"物流配送",
					icon:"&#xe631;",
					subhead:[{name:"配送服务"},{name:"验货签收"},{name:"全国网络"}]
				},{
					title:"关于我们",
					icon:"&#xe632;",
					subhead:[{name:"关于我们"},{name:"联系我们"}]
				}]
			}
		},
		watch:{
			$route(){
				this.route = this.$route.path;
			}
		},
		created() {
			this.route = this.$route.path;
		}
	}
</script>

<style>
</style>
