<template>
	<div class="header-top">
		<div class="wrapper">
			<div class="left">
				<router-link to='/' class="home-link"><i class="iconfont">&#xe612;</i><span>商城首页</span></router-link>
				<span>您好，欢迎来到解放轻卡备品商城</span>
				<router-link v-if="!userId" to='/login'><span class="login">请登录</span></router-link>
<!--				<router-link v-if="!userId" to='/register'><span class="register">免费注册</span></router-link>-->
			</div>
			<div class="right">
				<div class="order" v-if="userId" @mouseover="iconfontHover =true" @mouseout="iconfontHover =false">
					<router-link to='/myhome'><em>{{mobile}}</em></router-link>
					<i :class="{'el-icon-arrow-up':iconfontHover,'el-icon-arrow-down':!iconfontHover}"></i>
					<div class="drop-down">
						<ul>
							<li><router-link to='/myhome'>个人中心</router-link></li>
							<li><router-link to='/myhome/quick'>快速下单</router-link></li>
							<li><router-link to='/password'>修改密码</router-link></li>
							<li><a href="javascript:;" @click="logout">退出账号</a></li>
						</ul>
					</div>
				</div>
				<span v-if="userId">账户余额：{{ assets.balance }}元</span>
				<div class="order" v-if="userId" @mouseover="iconfontHover =true" @mouseout="iconfontHover =false">
					<router-link to='/myhome/myorder'><em>我的订单</em></router-link>
					<i :class="{'el-icon-arrow-up':iconfontHover,'el-icon-arrow-down':!iconfontHover}"></i>
					<div class="drop-down">
						<ul>
							<li><router-link :to="{ path: '/myhome/myorder', query: { dataType: 'payment' }}">待支付</router-link></li>
							<li><router-link :to="{ path: '/myhome/myorder', query: { dataType: 'delivery' }}">待发货</router-link></li>
							<li><router-link :to="{ path: '/myhome/myorder', query: { dataType: 'received' }}">待收货</router-link></li>
							<li><router-link :to="{ path: '/myhome/myorder', query: { dataType: 'comment' }}">待评价</router-link></li>
						</ul>
					</div>
				</div>
				<!-- <router-link to='' target="_blank"><span>商户中心</span></router-link> -->
				<!-- <span class="iphone">
					<a href="/h5" target="_blank">
						<i class="iconfont">&#xe615;</i>
						<em>手机版</em>
					</a>
					<img src="../assets/img/phone-code.png" class="phone-code"/>
				</span> -->
			</div>
		</div>
	</div>
</template>

<script>
	import * as UserApi from '@/api/user'
	export default {
		data() {
			return {
				iconfontHover:false,
				userId: localStorage.getItem('userId'),
				mobile: localStorage.getItem('mobile'),
				// 账户资产
				assets: { balance: '--', points: '--', coupon: '--' },
			};
		},
		created() {
			if(this.userId){
				this.getAsset()
			}
		},
		methods: {
			getAsset(){
				let app = this
				UserApi.assets()
				  .then(result => {
				    app.assets = result.data.assets
                      if (localStorage.getItem('checkAssets')) {
                          if (app.assets.balance < 1000) {
                              this.$message({
                                  message: '您的余额不足1000元，请及时充值',
                                  type: 'warning'
                              })
                          }
                          localStorage.removeItem('checkAssets')
                      }
				  })
			},
			logout(){
				this.$confirm('确定要退出吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					localStorage.clear()
          this.$router.go(0)
				}).catch(() => {
				});
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
