<template>
	<div class="recommend clearfix">
		<div class="home-title"><span><i class="el-icon-circle-check"></i>商城精选</span></div>
		<div class="recommend-list">
			<el-empty description="暂无精选产品" :image-size="50" v-if="recommendList.length===0"></el-empty>
			<ul>
				<li v-for="(item,index) in recommendList.slice(0,5)" :key="index">
					<router-link :to="{path:'/details',query: {id: item.goods_id}}">
						<img :src="item.goods_image"/>
						<p class="line1"><span>备件号：{{item.goods_no}}</span></p>
						<p class="line2"><span>名称：{{item.goods_name}}</span></p>
						<!-- <p><span>适用车型：</span>{{item.type}}</p> -->
						<p class="p-bottom">
							<span class="price">￥{{item.goods_price_min}}</span>
							<!-- <em class="cart-icon" :class="[{'active':item.cartSelect},{'shake':item.cartSelect}]" @click.prevent="addCart(item,index)">
								<i class="iconfont cart">&#xe604;</i>
								<i class="iconfont select" v-if="item.cartSelect">&#xe6b2;</i>
							</em> -->
						</p>
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			recommendList: Array
		},
		data() {
			return{
				
			}
		},
		methods:{
			addCart(item){
				if(!localStorage.getItem('userId')){
					this.$message({
						message: '温馨提示：请先登录！',
						type: 'warning'
					});
				}
				item.cartSelect = !item.cartSelect
				// event.preventDefault(); 
			}
		}
	}
</script>

<style>
</style>
