<template>
	<div class="main-menu">
		<div class="loading" v-if="loading">
			<i class="el-icon-loading"></i>
		</div>
		<!-- <div class="menu-title">
			<i class="iconfont">&#xe625;</i>
			<span>全部商品分类</span>
		</div> -->
		 <!-- @mouseleave="menuLeave()" -->
		<div class="menu-con">
			<ul>
				<!-- @mouseover="menuHover(index)" -->
				<li class="clearfix" v-for="(item,index) in categoryList" :key="index">
					<router-link :to="{ path: '/productlist', query: { categoryId: item.category_id }}">
						<div class="left">
							<i class="iconfont icon" v-if="index==0">&#xe649;</i>
							<i class="iconfont icon" v-else-if="index==1">&#xe626;</i>
							<i class="iconfont icon" v-else-if="index==2">&#xe662;</i>
							<i class="iconfont icon" v-else-if="index==3">&#xe7dc;</i>
							<i class="iconfont icon" v-else-if="index==4">&#xe62a;</i>
							<i class="iconfont icon" v-else-if="index==5">&#xe667;</i>
							<i class="iconfont icon" v-else-if="index==6">&#xe66a;</i>
							<i class="iconfont icon" v-else>&#xe822;</i>
							<span>{{item.name}}</span>
						</div>
						<!-- <i class="iconfont right-icon">&#xe793;</i> -->
					</router-link>
				</li>
			</ul>
			<!-- <div class="menu-class">
				<div v-for="(item,indexs) in categoryList" :key="indexs">
					<div class="menu-class-item clearfix" v-if="menuClassIndex == indexs">
						<div class="title"><i></i>{{item.name}}</div>
						<dl>
							<dt v-for="(value,i) in item.children" :key="i"><router-link :to="{ path: '/productlist', query: { categoryId: value.category_id }}">{{value.name}}</router-link></dt>
						</dl>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import * as CategoryApi from '@/api/category'
	export default {
		// props:{
		// 	categoryList: Array
		// },
		data() {
			return {
				loading:true,
				categoryList: [],
				menuClassIndex:'-1',
				menuList:[]
			}
		},
		watch:{
		},
		mounted() {
			this.getCategoryList()
		},
		methods:{
			getCategoryList(){
				CategoryApi.list().then(response => {
					this.loading=false
				  this.categoryList = response.data.list
				}).catch(err => {
				  console.log(err)
				})
			},
			// menuHover(index){
			// 	this.menuClassIndex = index
			// },
			// menuLeave(){
			// 	this.menuClassIndex = -1
			// }
		}
	}
</script>

<style lang="scss" scoped>
	.loading{
		text-align: center;
		.el-icon-loading{
			margin: 0 auto;
			font-size: 20px;
			color: rgba($color:#000, $alpha:0.15);
		}
	}
</style>
