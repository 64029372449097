import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'
import WxchatLayout from '../views/wechat/wechat-layout.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/details',
		name: 'productDetails',
		component: () => import( /* webpackChunkName: "productDetails" */
			'../views/product-details/product-details.vue')
	},
	{
		path: '/login',
		name: 'loginLayout',
		component: () => import( /* webpackChunkName: "loginLayout" */ '../views/login/login-layout.vue'),
		children: [{
			path: '/login',
			name: 'login',
			component: () => import( /* webpackChunkName: "login" */ '../views/login/login.vue')
		// }, {
		// 	path: '/register',
		// 	name: 'register',
		// 	component: () => import( /* webpackChunkName: "register" */ '../views/login/register.vue')
		}, {
			path: '/password',
			name: 'password',
			component: () => import( /* webpackChunkName: "password" */ '../views/login/password.vue')
		}]
	}, {
		path: '/serviceAgreement',
		name: 'serviceAgreement',
		component: () => import( /* webpackChunkName: "serviceAgreement" */ '../views/login/service-agreement.vue')
	}, {
		path: '/privacyAgreement',
		name: 'privacyAgreement',
		component: () => import( /* webpackChunkName: "privacyAgreement" */ '../views/login/privacy-agreement.vue')
	},
	// {
	// 	path: '/login',
	// 	name: 'Login',
	// 	component: () => import( /* webpackChunkName: "login" */
	// 		'../views/login/login.vue')
	// },
	// {
	// 	path: '/register',
	// 	name: 'Register',
	// 	component: () => import( /* webpackChunkName: "register" */
	// 		'../views/login/register.vue')
	// },
	// {
	// 	path: '/password',
	// 	name: 'Password',
	// 	component: () => import( /* webpackChunkName: "password" */
	// 		'../views/login/password.vue')
	// },
	{
		path: '/cart',
		name: 'Cart',
		component: () => import( /* webpackChunkName: "cart" */
			'../views/cart/cart.vue'),
		meta: {
			requireLogin: true
		}
	},
	{
		path: '/settlement',
		name: 'Settlement',
		component: () => import( /* webpackChunkName: "settlement" */
			'../views/settlement/settlement.vue'),
		meta: {
			requireLogin: true
		}
	},
	{
		path: '/settlement/nuonuo',
		name: 'Nuonuo',
		component: () => import( /* webpackChunkName: "settlement" */
			'../views/settlement/nuonuo.vue'),
		meta: {
			requireLogin: true
		}
	},
	{
		path: '/settlement/wxpay',
		name: 'SWxpay',
		component: () => import( /* webpackChunkName: "settlement" */
			'../views/settlement/wxpay.vue'),
		meta: {
			requireLogin: true
		}
	},
	{
		path: '/settlement/paySuccess',
		name: 'paySuccess',
		component: () => import( /* webpackChunkName: "settlement" */
			'../views/settlement/pay-success.vue'),
		meta: {
			requireLogin: true
		}
	},
	{
		path: '/productlist',
		name: 'productList',
		component: () => import( /* webpackChunkName: "productList" */
			'../views/product-list/product-list.vue')
	},
	{
		path: '/newslist',
		name: 'newsList',
		component: () => import( /* webpackChunkName: "newsList" */
			'../views/news/news-list.vue')
	},
	{
		path: '/newsdetails',
		name: 'newsDetails',
		component: () => import( /* webpackChunkName: "newsDetails" */
			'../views/news/news-details.vue')
	},
	{
		path: '/myhome',
		name: 'myHome',
		component: () => import( /* webpackChunkName: "myHome" */ '../views/my-home/my-home.vue'),
		children: [{
				// 这里不设置值，是把main作为默认页面
				path: '/myhome',
				name: 'Main',
				component: () => import( /* webpackChunkName: "main" */ '../views/my-home/my-homepage.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/import',
				name: 'myImport',
				component: () => import( /* webpackChunkName: "myorder" */ '../views/my-order/import.vue'),
				meta: {
					requireLogin: true
				}
			},
			{
				path: '/import_detail',
				name: 'detail',
				component: () => import( /* webpackChunkName: "cart" */
					'../views/import/detail.vue'),
				meta: {
					requireLogin: true
				}
			},
			{
				path: '/myhome/quick',
				name: 'quick',
				component: () => import( /* webpackChunkName: "cart" */
					'../views/cart/quick.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/myorder',
				name: 'myOrder',
				component: () => import( /* webpackChunkName: "myorder" */ '../views/my-order/my-order.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/myaddress',
				name: 'myAddress',
				component: () => import( /* webpackChunkName: "myaddress" */ '../views/my-home/my-address.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/evaluate',
				name: 'evaluate',
				component: () => import( /* webpackChunkName: "evaluate" */ '../views/evaluate/evaluate.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/orderdetails',
				name: 'orderDetails',
				component: () => import( /* webpackChunkName: "orderdetails" */
					'../views/my-order/order-details.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/returngoods',
				name: 'returnGoods',
				component: () => import( /* webpackChunkName: "returngoods" */
					'../views/return-goods/return-goods.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/returnlist',
				name: 'returnList',
				component: () => import( /* webpackChunkName: "returnlist" */
					'../views/return-goods/return-list.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/returndetails',
				name: 'returnDetails',
				component: () => import( /* webpackChunkName: "returndetails" */
					'../views/return-goods/return-details.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/wallet',
				name: 'myWallet',
				component: () => import( /* webpackChunkName: "wallet" */
					'../views/my-property/my-wallet.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/integral',
				name: 'myIntegral',
				component: () => import( /* webpackChunkName: "integral" */
					'../views/my-property/my-integral.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/coupon',
				name: 'coupon',
				component: () => import( /* webpackChunkName: "coupon" */
					'../views/coupon/coupon.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/mycoupon',
				name: 'myCoupon',
				component: () => import( /* webpackChunkName: "mycoupon" */
					'../views/coupon/my-coupon.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/help',
				name: 'myHelp',
				component: () => import( /* webpackChunkName: "myhelp" */
					'../views/my-help/my-help.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/bankcard',
				name: 'bankCard',
				component: () => import( /* webpackChunkName: "bankcard" */
					'../views/my-property/my-bankcard.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/psi-category',
				name: 'psiCategory',
				component: () => import( /* webpackChunkName: "bankcard" */
					'../views/psi/category.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/psi-goods',
				name: 'psiGoods',
				component: () => import( /* webpackChunkName: "bankcard" */
					'../views/psi/goods.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/psi-customer',
				name: 'psiCustomer',
				component: () => import( /* webpackChunkName: "bankcard" */
					'../views/psi/customer.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/psi-order',
				name: 'psiOrder',
				component: () => import( /* webpackChunkName: "bankcard" */
					'../views/psi/order.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/psi-inventory',
				name: 'psiInventory',
				component: () => import( /* webpackChunkName: "bankcard" */
					'../views/psi/inventory.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/psi-inventorydetail',
				name: 'psiInventoryDetail',
				component: () => import( /* webpackChunkName: "bankcard" */
					'../views/psi/inventorydetail.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/express',
				name: 'express',
				component: () => import( /* webpackChunkName: "bankcard" */
					'../views/express/list.vue'),
				meta: {
					requireLogin: true
				}
			}, {
				path: '/myhome/inventory',
				name: 'inventory',
				component: () => import( /* webpackChunkName: "bankcard" */
					'../views/inventory/list.vue'),
				meta: {
					requireLogin: true
				}
			}
		]
	},
	{
		path: '/qywx',
		name: 'QywxLayout',
		component: () => import( /* webpackChunkName: "QywxLayout" */ '../views/qywx/qywx-layout.vue'),
		children: [{
			path: '/qywx',
			name: 'index',
			component: () => import( /* webpackChunkName: "index" */ '../views/qywx/index.vue'),
			meta:{
				header:false
			}
		},{
			path: '/qywx/custom',
			name: 'custom',
			component: () => import( /* webpackChunkName: "custom" */ '../views/qywx/custom/custom.vue'),
			meta:{
				header:true
			}
		},{
			path: '/qywx/placeOrder',
			name: 'placeOrder',
			component: () => import( /* webpackChunkName: "placeOrder" */ '../views/qywx/place-order/place-order.vue'),
			meta:{
				header:true,
				name:'下单'
			}
		},{
			path: '/qywx/checkPiece',
			name: 'checkPiece',
			component: () => import( /* webpackChunkName: "checkPiece" */ '../views/qywx/check-piece/check-piece.vue'),
			meta:{
				header:true
			}
		},{
			path: '/qywx/checkResult',
			name: 'checkResult',
			component: () => import( /* webpackChunkName: "checkResult" */ '../views/qywx/check-piece/check-result.vue'),
			meta:{
				header:true
			}
		},{
			path: '/qywx/orderGoods',
			name: 'orderGoods',
			component: () => import( /* webpackChunkName: "orderGoods" */ '../views/qywx/order-goods/order-goods.vue'),
			meta:{
				header:true
			}
		},{
			path: '/qywx/orderDetails',
			name: 'qywxOrderDetails',
			component: () => import( /* webpackChunkName: "qywxOrderDetails" */ '../views/qywx/order-goods/order-details.vue'),
			meta:{
				header:true
			}
		}]
	},
	{
		path: '/qywx-bd',
		name: 'QywxLayout',
		component: () => import( /* webpackChunkName: "QywxLayout" */ '../views/qywx-bd/qywx-layout.vue'),
		children: [{
			path: '/qywx-bd',
			name: 'index',
			component: () => import( /* webpackChunkName: "index" */ '../views/qywx-bd/index.vue'),
			meta:{
				header:false
			}
		},{
			path: '/qywx-bd/custom',
			name: 'custom',
			component: () => import( /* webpackChunkName: "custom" */ '../views/qywx-bd/custom/custom.vue'),
			meta:{
				header:true
			}
		},{
			path: '/qywx-bd/placeOrder',
			name: 'placeOrder',
			component: () => import( /* webpackChunkName: "placeOrder" */ '../views/qywx-bd/place-order/place-order.vue'),
			meta:{
				header:true,
				name:'下单'
			}
		},{
			path: '/qywx-bd/checkPiece',
			name: 'checkPiece',
			component: () => import( /* webpackChunkName: "checkPiece" */ '../views/qywx-bd/check-piece/check-piece.vue'),
			meta:{
				header:true
			}
		},{
			path: '/qywx-bd/checkResult',
			name: 'checkResult',
			component: () => import( /* webpackChunkName: "checkResult" */ '../views/qywx-bd/check-piece/check-result.vue'),
			meta:{
				header:true
			}
		},{
			path: '/qywx-bd/orderGoods',
			name: 'orderGoods',
			component: () => import( /* webpackChunkName: "orderGoods" */ '../views/qywx-bd/order-goods/order-goods.vue'),
			meta:{
				header:true
			}
		},{
			path: '/qywx-bd/orderDetails',
			name: 'qywxOrderDetails',
			component: () => import( /* webpackChunkName: "qywxOrderDetails" */ '../views/qywx-bd/order-goods/order-details.vue'),
			meta:{
				header:true
			}
		}]
	},
	{
		path: '/wxpay',
		name: 'WxchatLayout',
		component: WxchatLayout,
		children: [{
			path: '/wxpay',
			name: 'wxpay',
			component: () => import( /* webpackChunkName: "wechat" */
				'../views/wechat/wxpay.vue')
		}]
	},
	{
		path: '/settledApply',
		name: 'settledApply',
		component: () => import( /* webpackChunkName: "settledApply" */
			'../views/apply-for/settled-apply.vue')
	}
]

const router = new VueRouter({
	// mode: 'hash',
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
