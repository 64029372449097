<template>
	<div class="homepage">
		<!-- 头部 -->
		<mainHeader></mainHeader>
		<div class="wrapper">
			<!-- 轮播 -->
			<firstScreen :items="items" :categoryList="categoryList" :userId="userId"></firstScreen>
			<!-- 推荐产品 -->
			<recommend :recommendList="recommendList" v-if="userId"></recommend>
		</div>
		<advertisement></advertisement>
		<div class="wrapper">
			<!-- 产品列表 -->
			<productList :categoryList="categoryList"></productList>
		</div>
		<!-- 尾部 -->
		<mainFooter></mainFooter>
	</div>
</template>

<script>
	import mainHeader from '@/components/header.vue'
	import advertisement from '@/components/advertisement.vue'
	import firstScreen from './components/first-screen.vue'
	import recommend from './components/recommend.vue'
	import productList from './components/product-list.vue'
	import mainFooter from '../../components/footer.vue'
	import * as PagesApi from '@/api/page'
	import * as CategoryApi from '@/api/category'
	export default {
		name: 'Home',
		components: {
			mainHeader,
			advertisement,
			firstScreen,
			recommend,
			productList,
			mainFooter
		},
		data() {
			return {
				userId: localStorage.getItem('userId'),
				page: '',
				items: [],
				categoryList: [],
				recommendList: [],
			}
		},
		created() {
			this.loadPages()
			this.getCategoryList()
		},
		methods: {
			loadPages() {
				let app = this
				PagesApi.detail(0).then(response => {
					const {
						data: {
							pageData
						}
					} = response
					app.page = pageData.page
					app.items = pageData.items
					app.items.forEach(item => {
						if (item.name == '文章组') {
							app.newsList = item.data
						} else if (item.name == '商品组') {
							app.recommendList = item.data.slice(0, 5)
						}
					})
				}).catch(err => {
					console.log(err)
				})
			},
			getCategoryList() {
				CategoryApi.list().then(response => {
					this.categoryList = response.data.list
				}).catch(err => {
					console.log(err)
				})
			},
		}
	}
</script>

<style lang='scss'>
	@import "../../assets/css/home.scss"
</style>