import request from '@/utils/request'

// api地址
const api = {
    list: 'goods/list',
    detail: 'goods/detail',
    inventoryList: 'goods/inventoryList',
}

// 商品列表
export const list = param => {
    const newParam = {
        ...param,
        from: 'B'
    }
  return request({
      url: api.list,
      method: 'get',
  	params: newParam,
    })
}

// 商品详情
export const detail = goodsId => {
  return request({
      url: api.detail,
      method: 'get',
  	params: { goodsId }
    })
}

export const inventoryList = param => {
    return request({
        url: api.inventoryList,
        method: 'get',
        params: param,
    })
}
