<template>
	<div class="column-nav clearfix">
		<div class="wrapper">
			<div class="nav">
				<div v-for="(item,index) in titleList" :key="index" class="nav-item" @click="navItemClick(item)">
					<i class="iconfont" v-html="item.icon"></i><span>{{item.title}}</span>
					<leftMenu :categoryList="categoryList" v-if="item.title=='产品分类'"></leftMenu>
				</div>
			</div>
			<slot name="breadcrumb" ></slot>
		</div>
	</div>
</template>

<script>
	import leftMenu from "./menu.vue"
	export default {
		props:{
			categoryList: Array
		},
		components: {
			leftMenu
		},
		data() {
			return {
				titleList: [{
					id: 1,
					title: "首页",
					icon:'&#xe639;',
					url: '/'
				},{
					id: 2,
					title: "产品分类",
					icon:'&#xe625;',
					url: '/productlist'
				},
				// {
				// 	id: 3,
				// 	title: "优惠活动",
				// 	icon:'&#xe661;',
				// 	url: '/myhome/mycoupon'
				// },
				{
					id: 4,
					title: "公告资讯",
					icon:'&#xe603;',
					url: '/newslist'
				}, {
					id: 5,
					title: "快速下单",
					icon:'&#xe8b6;',
					url: '/myhome/quick'
				}]
			}
		},
		methods:{
			navItemClick(item){
				this.$router.push(item.url)
			}
		}
	}
</script>

<style lang="scss">
</style>
