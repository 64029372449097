<template>
    <keep-alive>
      <router-view />
    </keep-alive>
</template>

<script>
export default {
  data() {
    return {
      route: null,
    };
  },
  watch: {
    $route() {
      this.route = this.$route.path;
    },
  },
  created() {
    this.route = this.$route.path;
  },
};
</script>

<style lang="scss" scoped>

</style>
